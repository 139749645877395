<template>
    <div class="page-header"
         :class="[{'page-header-small': !extraBig && (isMobile || small)}, {'page-header-bigger': !isMobile && !small && bigger}, {'page-header-extra-big': extraBig}]">
        <parallax
            class="page-header-image"
            :style="[
              {'background-image': 'url(' + src + ')'},
              {'background-position': 'center ' + verticalPosition},
              {'z-index': zIndex}
          ]"
        >
        </parallax>
        <div class="content-center">
            <div class="container">
                <h1 v-if="title" class="title">{{ $t(title) }}</h1>
                <b-row v-if="buttons !== null && buttons.length > 0" align-h="center">
                    <router-link tag="button" v-for="button in buttons" :key="button.text"
                                 :class="buttonClass(button.variant, button.color)" :to="{name: button.link}">
                        {{ button.text }}
                    </router-link>
                </b-row>
                <div v-if="socialMedia" class="text-center">
                    <a href="https://www.facebook.com/SportfogadokClubja" target="_blank"
                       class="btn btn-primary btn-lg btn-icon btn-round">
                        <i class="fab fa-facebook"></i>
                    </a>
                    <a href="https://www.instagram.com/sportfogadokclubja" target="_blank"
                       class="btn btn-primary btn-lg btn-icon btn-round">
                        <i class="fab fa-instagram"></i>
                    </a>
                    <a href="https://t.me/SportfogadokClubja" target="_blank"
                       class="btn btn-primary btn-lg btn-icon btn-round">
                        <i class="fab fa-telegram"></i>
                    </a>
                </div>
                <h3 v-if="text" class="text-center">{{ $t(text) }}</h3>
            </div>
        </div>
        <div v-if="!isMobile && extraBig" class="content-bottom">
            <b-row v-if="paragraphs !== null && paragraphs.length > 0" class="w-100" align-h="center">
                <b-col cols="4" xl="3" v-for="(p, index) in paragraphs" :key="p[0]">
                    <b-row class="m-0 p-0">
                        <div class="m-0 mr-2 p-0 text-golden text-right" style="font-size: 4rem; line-height: 1">
                            {{ index + 1 }}
                        </div>
                        <b-col class="m-0 p-0 text-justify">
                            <b-row class="m-0">
                                <div class="text-golden h3 m-0">{{ p[0] }}</div>
                            </b-row>
                            <b-row class="m-0">
                                <div class="h5">{{ p[1] }}</div>
                            </b-row>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
        </div>
        <div class="content-bottom" v-if="!isMobile">
            <b-row align-h="around">
                <b-col v-for="(item, index) in menu" :key="item.to">
                    <router-link style="position: relative;" class="m-auto index-menu-item" tag="div"
                                 :style="menuStyle(item.img, index)" :to="item.to"></router-link>
                    <div style="position: absolute; bottom:10%;left:0; right:0;" class="font-weight-bold h5 m-0">
                        {{ $t(item.title) }}
                    </div>
                </b-col>
            </b-row>
        </div>
        <div class="content-bottom" style="top:50% !important;" v-else>
            <b-row align-h="center">
                <b-col class="my-3" cols="6" v-for="(item, index) in menu" :key="item.to">
                    <router-link style="position: relative;" class="m-auto index-menu-item" tag="div"
                                 :style="menuStyle(item.img, index)" :to="item.to"></router-link>
                    <div style="position: absolute; bottom:10%;left:0; right:0;margin: auto;" class="font-weight-bold h5 m-0">
                        {{ $t(item.title) }}
                    </div>
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
export default {
    name: "parallax-page-header",
    props: {
        src: {
            type: String,
            default: '/img/test-bg1.jpg',
            description: 'Picture path to show'
        },
        title: {
            type: String,
            default: '',
            description: 'Title on parallax picture'
        },
        text: {
            type: String,
            default: '',
            description: 'Text under title on parallax picture'
        },
        buttons: {
            type: Array,
            default: null,
            description: 'Buttons'
        },
        socialMedia: {
            type: Boolean,
            default: false,
            description: 'Show social media links on parallax picture'
        },
        paragraphs: {
            type: Array,
            default: null,
            description: 'Additional paragraphs at the bottom'
        },
        verticalPosition: {
            type: String,
            default: 'center',
            description: 'Adjust parallax background image position'
        },
        zIndex: {
            type: Number,
            default: -4
        },
        small: {
            type: Boolean,
            default: false
        },
        bigger: {
            type: Boolean,
            default: false
        },
        extraBig: {
            type: Boolean,
            default: false
        },
        menu: {
            type: Array,
            default: null
        }
    },
    methods: {
        buttonClass(type, color) {
            let variant = 'btn-' + type;
            return 'mx-2 h5 text-' + color + ' font-weight-bold btn btn-lg ' + variant;
        },
        menuStyle(img, index) {

            let boxShadow = "box-shadow: 5px 5px 5px 5px #00000077;";
            let bg = "background-image: url('" + img + "');";

            return "background-size: cover;background-position: center;width: 100%;max-width: 300px;height: 200px;" +
                bg + "background-repeat: no-repeat;border-radius: 2rem;" + boxShadow;
        }
    },
    computed: {
        isMobile() {
            return this.$root.isMobile;
        }
    }
}
</script>

<style scoped>
.index-menu-item {
    filter: grayscale(100%);
}

.index-menu-item:hover {
    filter: grayscale(0%) !important;
    cursor: pointer;
}
</style>