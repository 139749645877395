<template>
    <navbar position="fixed"
            type="primary"
            menu-classes="ml-auto"
            :color-on-scroll="getColorOnScroll">
        <template>
            <router-link v-popover:popover1 class="navbar-brand p-0 text-navbar" to="/">
                <div class="nav-site-name">{{ $t('siteName') }}</div>
            </router-link>
        </template>
        <template slot="navbar-menu">
            <li v-for="(menuItem) in menuItems" :key="menuItem.to" class="nav-item">
                <router-link class="nav-link" :to="menuItem.to"><p style="font-size: 14px;">
                    {{ $t(menuItem.title) }}</p></router-link>
            </li>
        </template>
    </navbar>
</template>

<script>
import {DropDownHover, NavbarToggleButton, Navbar, NavLink} from '@/components';
import {Popover} from 'element-ui';
import store from "../plugins/store";

export default {
    name: 'silver-navbar',
    data() {
        return {
            dropdownNeeded: false,
            menuItems: []
        }
    },
    props: {
        colorOnScroll: {
            type: Number,
            default: 0
        }
    },
    computed: {
        isMobile() {
            return this.$root.isMobile;
        },
        getColorOnScroll() {
            return this.colorOnScroll;
        },
        userRole() {
            if (!store.getters.user) return 'guest';
            return store.getters.user.userRole;
        }
    },
    components: {
        Navbar,
        [Popover.name]: Popover
    },
    methods: {
        onSelectLanguage(lang) {
            this.$i18n.locale = lang
        },
        initMenuItems() {
            this.menuItems = [
                {
                    to: 'introduction',
                    title: 'pages.introduction'
                },
                {
                    to: 'achievements',
                    title: 'pages.achievements'
                },
                {
                    to: 'tgf',
                    title: 'pages.tgf'
                },
              {
                to: 'rules',
                title: 'pages.rules'
              }
            ];
        },
        reloadMenus() {
            this.initMenuItems();
        }
    },
    mounted() {
        this.reloadMenus();
    }
};
</script>

<style>
.text-navbar .nav-link {
    font-size: 1.1em !important;
}

.nav-site-name {
    font-size: 1.2em;
    vertical-align: middle;
    display: inline-block;
}

.nav-item {
    cursor: pointer;
}

.dropdown-item {
    cursor: pointer;
}

ul.dropdown-menu {
    overflow: hidden !important;
    height: auto !important;
}

</style>
