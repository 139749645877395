<template>
    <div>
        <b-row>
            <b-col>
                <parallax-page-header src="/img/hero-shadowlands.jpg" title="Silver River"
                                      :extra-big="true" :z-index="-1" text="EU - Ragnaros" :menu="menu"/>
            </b-col>
            <div class="wild-horse">

            </div>
        </b-row>
    </div>
</template>
<script>

import ParallaxPageHeader from "@/pages/components/ParallaxPageHeader";

export default {
    name: 'index',
    components: {
        ParallaxPageHeader
    },
    computed: {
        windowWidth() {
            return this.$root.windowWidth;
        },
        windowHeight() {
            return this.$root.windowHeight;
        }
    },
    data() {
        return {
            mobileView: true,
            paragraphs: [
                ['Hosszútávú profit', 'Csapatunk több, mint egy éve profitál előfizetői számára'],
                ['Információkon alapuló tippek', 'Soha nem hasraütésszerűen adunk fogadási tanácsot, mint a legtöbb oldal! Mindig megfontolt, belső információkon alapulnak tippjeink, mellyel bizonyítottuk, hogy hosszútávon ezzel a módszerrel igenis profitábilis a sportfogadás'],
                ['Sportfogadó közösség', 'Telegram csoportunk sok olyan taggal rendelkezik, akik hosszú hónapok óta bizalommal fizetnek elő hozzánk, nem hiába, így kialakult egy remek közösség is']
            ],
            menu: [
                {
                    to: 'introduction',
                    title: 'pages.introduction',
                    img: '/img/hero-intro.webp'
                },
                {
                    to: 'achievements',
                    title: 'pages.achievements',
                    img: '/img/hero-achi.jpg'
                },
                {
                    to: 'tgf',
                    title: 'pages.tgf',
                    img: '/img/hero-tgf.jpg'
                }
            ]
        }
    },
    methods: {
        checkMobileView() {
            let ratio = this.windowWidth / this.windowHeight;
            this.mobileView = ratio < 1.2;
        }
    },
    mounted() {
        this.checkMobileView();
    },
    watch: {
        windowWidth() {
            this.checkMobileView();
        },
        windowHeight() {
            this.checkMobileView();
        }
    }
};
</script>
<style>
.wild-horse {
    position: absolute;
    top: 200px;
    left: -120px;
    width: 200px;
    height: 400px;
    background-image: url('/img/horse.png');
    background-size: cover;
    -webkit-transform: rotateX(0deg) rotateY(180deg) rotateZ(-25deg);
    -moz-transform: rotateX(0deg) rotateY(180deg) rotateZ(-25deg);
    transform: rotateX(0deg) rotateY(180deg) rotateZ(-25deg);
}
</style>
