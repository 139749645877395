<template>
  <div class="introduction-page">
    <div class="overflow-hidden" :class="bodyClass">
      <parallax-page-header class="clear-filter" filter-color="introduction-cover-shadow" :src="heroSrc" :title="title"
                            :social-media="socialMedia" :text="text"
                            :verticalPosition="parallaxVerticalPosition" :bigger="true" :z-index="-1"/>

      <parallax class="page-parallax-bg"></parallax>
      <div style="width: 100%;" class="clear-filter" filter-color="introduction-page-shadow">
        <div class="container clear-filter" filter-color="introduction-page-shadow-text">
          <IntroductionContent :paragraph-class="paragraphClass" :paragraph-col-class="paragraphColClass" />
          <Officers></Officers>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ParallaxPageHeader from "../components/ParallaxPageHeader";
import Parallax from "@/components/Parallax";
import Officers from "@/components/Officers";
import IntroductionContent from "@/pages/introduction/IntroductionContent";

export default {
  name: 'introduction',
  components: {
    ParallaxPageHeader,
    Parallax,
    Officers,
    IntroductionContent
  },
  computed: {
    windowWidth() {
      return this.$root.windowWidth;
    },
    isMobile() {
      return this.$root.isMobile;
    },
    paragraphClass() {
      if (this.isMobile) return ['m-page-paragraph'];
      else return ['page-paragraph'];
    },
    bodyClass() {
      return ['page-body'];
    },
    paragraphColClass() {
      return ['col-12', 'col-md-11', 'col-lg-10', 'col-xl-9', 'm-auto'];
    }
  },
  data() {
    return {
      heroSrc: '/img/hero-intro.webp',
      title: 'Silver River',
      text: '',
      socialMedia: false,
      parallaxVerticalPosition: 'top'
    };
  }
};
</script>

<style scoped>
.page-parallax-bg {
  background-image: url("/img/bg-shadowlands.jpg");
  background-size: cover;
  background-position: center;
  z-index: -3;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}
</style>