<template>
  <div>
    <div class="page-style-2" :class="paragraphClass">
      <div :class="paragraphColClass">
        <h3 class="title text-center">Rólunk</h3>
        <div class="text-justify">
          A Silver River a Ragnaros szerver egyik legrégebbi klánja, 2009 júniusában került megalapításra.
          Korábban az alli oldalt erősítettük, viszont átcsábultunk a horda oldalára 2021 októberében. Ha
          hallottál a Silver River-ről valószínűleg te sem ma kezdted a játékot, viszont a mostani progresselésünk
          már nem tükrözi a korábbi hírnevünket.
        </div>
      </div>
    </div>
    <div class="page-style-2" :class="paragraphClass">
      <div :class="paragraphColClass">
        <div class="text-justify">
          A guild célja, fő feladata egy olyan közösség kialakítása és összetartása, akik játékbeli
          tevékenységüket baráti hangulatban akarják eltölteni, és emellett hasonló eredményeket elérő, megfelelő
          képességű játékosokkal karöltve akarnak szembenézni a World of Warcraft raid kihívásaival a legmagasabb
          nehézségi szinteken úgy, hogy a játékon kívüli életre (IRL) is maradjon idő.
        </div>
      </div>
    </div>
    <div class="page-style-2" :class="paragraphClass">
      <div :class="paragraphColClass">
        <div class="text-justify">
          Félreértés ne essék, szeretnénk Mythicen clearelni az aktuális raidet, viszont a tagjaink nagy része
          dolgozik, családja van, és ezt figyelembe véve már csak 2-3 progress raid napot tartunk (magyar idő
          szerint):
          <div class="px-lg-5 mr-lg-5">
            <ul>
              <li class="list-2c">
                <div>Szerda</div>
                <div>20:00 - 23:00</div>
              </li>
              <li class="list-2c">
                <div>Csütörtök(altos HC)</div>
                <div>20:00 - 23:00</div>
              </li>
              <li class="list-2c">
                <div>Vasárnap</div>
                <div>20:00 - 23:00</div>
              </li>
            </ul>
          </div>
        </div>
        Progess időszakban (content patchnél max 1 hónap):
        <div class="px-lg-5 mr-lg-5">
          <ul>
            <li class="list-2c">
              <div>Szerda</div>
              <div>20:00 - 23:00</div>
            </li>
            <li class="list-2c">
              <div>Csütörtök(progress raidnap)</div>
              <div>20:00 - 23:00</div>
            </li>
            <li class="list-2c">
              <div>Vasárnap</div>
              <div>20:00 - 23:00/24:00</div>
            </li>
          </ul>
        </div>
        Ezeken a raidnapokon viszont igenis komolyan vesszük a játékot, felkészülve indulunk neki. A felzárkózni
        kívánóknak tartunk egy másodlagos raid napot is, teljesen fakultatív jelleggel Csütörtök 20:00-tól
        kifulladásig, főleg fun jelleggel, sörözgetve, beszélgetve, nem rástresszelve. Ide lehet hozni alt
        karaktereket, ha main-nek már nem kell onnan semmi. Szólunk, ha valakinek cserélnie kell, hogy haladjunk
        is. Ha csatlakozni kívánnál hozzánk, csupán néhány elvárásnak kell megfelelned:
        <ul class="my-2">
          <li>Ismerd a karaktered</li>
          <li>Rendelkezz progress tapasztalattal</li>
          <li>Contentnek megfelelő gear</li>
          <li>Türelem és civilizált viselkedés másokkal szemben</li>
          <li>Discord használata (mikrofonnal együtt)</li>
          <li>A raidekre potion-t kell csak hoznod, a flaskát és kaját a guild állja</li>
        </ul>
        Minden potenciális új jelentkezőt szívesen meghallgatunk, de a weboldalon megtalálható, hogy a progress csapatba
        milyen pozíciókra van üresedés.
        a klánszabályzat elejét tedd be
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "IntroductionContent",
  props: {
    paragraphClass: Object,
    paragraphColClass: Object
  }
}
</script>

<style scoped>

</style>