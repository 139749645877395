import Vue from 'vue';
import Router from 'vue-router';
import Index from './pages/index/Index.vue';
import SilverNavbar from './layout/SilverNavbar.vue';
import StarterFooter from './layout/SilverFooter.vue';
import Introduction from './pages/introduction/Introduction.vue';
import Achievements from './pages/achievements/Achievements.vue';
import Tgf from './pages/tgf/Tgf.vue';
import Rules from './pages/rules/Rules.vue';
import Schedule from './pages/schedule/Schedule';

Vue.use(Router);

export default new Router({
    mode: 'history',
    routes: [
        {
            path: '/',
            name: 'index',
            components: {
                default: Index,
                header: SilverNavbar,
                footer: StarterFooter
            },
            props: {
                header: {colorOnScroll: 220},
                footer: {backgroundColor: 'black'}
            }
        },
        {
            path: '/introduction',
            name: 'introduction',
            components: {
                default: Introduction,
                header: SilverNavbar,
                footer: StarterFooter
            },
            props: {
                header: {colorOnScroll: 200},
                footer: {backgroundColor: 'black'}
            }
        },
        {
            path: '*',
            redirect: '/'
        },
        {
            path: '/achievements',
            name: 'achievements',
            components: {
                default: Achievements,
                header: SilverNavbar,
                footer: StarterFooter
            },
            props: {
                header: {colorOnScroll: 200},
                footer: {backgroundColor: 'black'}
            }
        },
        {
            path: '/tgf',
            name: 'tgf',
            components: {
                default: Tgf,
                header: SilverNavbar,
                footer: StarterFooter
            },
            props: {
                header: {colorOnScroll: 200},
                footer: {backgroundColor: 'black'}
            }
        },
        {
            path: '/rules',
            name: 'rules',
            components: {
                default: Rules,
                header: SilverNavbar,
                footer: StarterFooter
            },
            props: {
                header: {colorOnScroll: 200},
                footer: {backgroundColor: 'black'}
            }
        },
        {
            path: '/schedule',
            name: 'schedule',
            components: {
                default: Schedule,
                header: SilverNavbar,
                footer: StarterFooter
            },
            props: {
                header: {colorOnScroll: 200},
                footer: {backgroundColor: 'black'}
            }
        },
        {
            path: '*',
            redirect: '/'
        }
    ],
    scrollBehavior: to => {
        if (to.hash) {
            return {selector: to.hash};
        } else {
            return {x: 0, y: 0};
        }
    }
});
