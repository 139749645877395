<template>
    <div class="introduction-page">
        <div class="overflow-hidden" :class="bodyClass">
            <parallax-page-header class="clear-filter" filter-color="introduction-cover-shadow" :src="heroSrc"
                                  :title="title" :social-media="socialMedia" :text="text"
                                  :verticalPosition="parallaxVerticalPosition" :bigger="true" :z-index="-1"/>
            <parallax class="page-parallax-bg"></parallax>
            <div style="width: 100%;" class="clear-filter" filter-color="introduction-page-shadow">
                <div class="container">
                    <div class="page-style-2" :class="paragraphClass">
                        <div :class="paragraphColClass">
                            <h3 class="title text-center">Jelenleg nyílt tagfelvétel van</h3>
                            <div class="text-justify">
                                Bárki jelentkezhet, szívesen várunk mindenkit. Nyilván nem kerül senki egyből progress
                                csapatba. Bizonyítani kell, hogy te is a közösség része szeretnél lenni
                                és értesz ahhoz, amit csinálsz.
                            </div>
                        </div>
                    </div>
                    <div class="page-style-2" :class="paragraphClass">
                        <div :class="paragraphColClass">
                            <div class="text-justify">
                                <h3 class="title text-center">Akiket főként keresünk</h3>
                                <ul>
                                    <li>Priest</li>
                                    <li>Monk</li>
                                </ul>
                                Ettől függetlenül mindenkit szívesen várunk, kulcsozzunk hét közben, illetve ha lesz
                                elég ember, indítani fogunk B csapatot is.
                            </div>
                        </div>
                    </div>
                    <div class="page-style-2" :class="paragraphClass">
                        <div :class="paragraphColClass">
                            <div class="text-justify">
                                <h3 class="title text-center">Raid napok</h3>
                                2 progress raid napot tartunk:
                                <ul>
                                    <li>Szerda 20:00-23:00</li>
                                    <li>Vasárnap 19:00-23:00</li>
                                </ul>
                                Csütörtökön altos/maines raidet tartunk opcionálisan 20:00 órától, főleg a kisebb
                                gearben lévő karakterek fejlesztésére.
                            </div>
                        </div>
                    </div>
                    <div class="page-style-2" :class="paragraphClass">
                        <div :class="paragraphColClass">
                            <div class="text-justify">
                                <h3 class="title text-center">Elvárásaink</h3>
                                <ul>
                                    <li>Ismerd a karakteredet</li>
                                    <li>Rendelkezz progress tapasztalattal</li>
                                    <li>245 feletti gear</li>
                                    <li>Türelem és civilizált viselkedés másokkal szemben</li>
                                    <li>Discord használata (mikrofonnal együtt)</li>
                                    <li>A raidekre potion-t kell csak hoznod, a flaskát és kaját a klán állja</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <Officers></Officers>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ParallaxPageHeader from "../components/ParallaxPageHeader";
import Officers from "@/components/Officers";

export default {
    name: 'tgf',
    components: {
        Officers,
        ParallaxPageHeader
    },
    computed: {
        windowWidth() {
            return this.$root.windowWidth;
        },
        isMobile() {
            return this.$root.isMobile;
        },
        paragraphClass() {
            if (this.isMobile) return ['m-page-paragraph'];
            else return ['page-paragraph'];
        },
        bodyClass() {
            return ['page-body'];
        },
        paragraphColClass() {
            return ['col-12', 'col-md-11', 'col-lg-10', 'col-xl-9', 'm-auto'];
        }
    },
    data() {
        return {
            heroSrc: '/img/hero-tgf.jpg',
            title: 'pages.tgf',
            text: '',
            socialMedia: false,
            parallaxVerticalPosition: 'top'
        };
    }
};
</script>

<style scoped>
.page-parallax-bg {
    background-image: url("/img/bg-horde.webp");
    background-size: cover;
    background-position: center;
    z-index: -3;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
}
</style>