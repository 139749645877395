<template>
  <div class="introduction-page">
    <div class="overflow-hidden" :class="bodyClass">
      <parallax-page-header class="clear-filter" filter-color="introduction-cover-shadow" :src="heroSrc" :title="title"
                            :social-media="socialMedia" :text="text"
                            :verticalPosition="parallaxVerticalPosition" :bigger="true" :z-index="-1"/>

      <parallax class="page-parallax-bg"></parallax>
      <div style="width: 100%;" class="clear-filter" filter-color="introduction-page-shadow">
        <div class="container clear-filter">
          <div class="page-style-2" :class="paragraphClass">
            <div :class="paragraphColClass">
              <h3 class="title text-center">Shadowlands</h3>
              <div class="text-justify">
                Aktuális progessünk (Sepulcher of the First Ones):
                <ul>
                  <li>Mythic 4/11</li>
                  <li>Heroic 11/11</li>
                </ul>
                Nem raid napokon, illetve raid után is szívesen kulcsozunk, akár magasabb szinten is, ha van fent elég
                ember.
                Aki szeretné, <a href="https://www.warcraftlogs.com/guild/progress/10106"
                                 target="_blank">Warcraftlogs</a>on,
                <a href="https://raider.io/guilds/eu/ragnaros/Silver%20River" target="_blank">Raider.IO</a>n vagy <a
                  href="https://www.wowprogress.com/guild/eu/ragnaros/Silver+River" target="_blank">WoW Progress</a>en
                is megnézheti az aktuális információkat.
              </div>
            </div>
          </div>
          <div class="page-style-2" :class="paragraphClass">
            <div :class="paragraphColClass">
              <h3 class="title text-center">Előző contentek</h3>
              <div class="text-justify">
                A Silver River guild visszanyúl majdnem az idők kezdetéhez, fénykorát talán
                Cataclysm/Pandaria környékén élte, még az Alliance oldalán. Sok Cutting Edge achievement
                vadászaton és realm first versenyzéseken vettünk részt, a játékot munka szinten komolyan
                véve.
              </div>
            </div>
          </div>
          <div class="page-style-2" :class="paragraphClass">
            <div :class="paragraphColClass">
              <div class="text-justify">
                Azóta persze telt az idő, kicsit szétszéledt a társaság, ki-ki átment a Horde oldalára,
                szerencsét próbált más társaságokban. Most a 9.1.5 közeledtével, mivel az alli oldalán túl
                kevés játékos volt, csatlakoztunk néhány ismerőshöz a horda oldalán (köztük régi Silver
                River játékosokhoz).
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ParallaxPageHeader from "../components/ParallaxPageHeader";

export default {
  name: 'achievements',
  components: {
    ParallaxPageHeader
  },
  computed: {
    windowWidth() {
      return this.$root.windowWidth;
    },
    isMobile() {
      return this.$root.isMobile;
    },
    paragraphClass() {
      if (this.isMobile) return ['m-page-paragraph'];
      else return ['page-paragraph'];
    },
    bodyClass() {
      return ['page-body'];
    },
    paragraphColClass() {
      return ['col-12', 'col-md-11', 'col-lg-10', 'col-xl-9', 'm-auto'];
    }
  },
  data() {
    return {
      heroSrc: '/img/hero-achi.jpg',
      title: 'pages.achievements',
      text: '',
      socialMedia: false,
      parallaxVerticalPosition: 'top'
    };
  }
};
</script>

<style scoped>
.page-parallax-bg {
  background-image: url("/img/bg-ali-horde.jpg");
  background-size: cover;
  background-position: center;
  z-index: -3;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 200px;
}
</style>