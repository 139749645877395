<template>
    <div class="introduction-page">
      <div class="overflow-hidden" :class="bodyClass">
        <parallax class="page-parallax-bg"></parallax>
        <div style="width: 100%;" class="clear-filter" filter-color="introduction-page-shadow">
          <div class="container clear-filter" filter-color="introduction-page-shadow-text">
            <iframe style="border: 0; margin-top: 80px; min-height: calc(100vh - 180px)" width="100%" src="https://firebasestorage.googleapis.com/v0/b/silver-river-d1243.appspot.com/o/public%2Fdoc%2F9.2_menetrend.pdf?alt=media&embedded=true"></iframe>
          </div>
        </div>
      </div>
      <div class="wild-horse">

      </div>
    </div>
</template>
<script>

export default {
  name: "Schedule",
  computed: {
    bodyClass() {
      return ['page-body'];
    }
  }
}
</script>

<style scoped>

.page-parallax-bg {
  background-image: url("/img/bg-shadowlands.jpg");
  background-size: cover;
  background-position: center;
  z-index: -3;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}


.wild-horse {
  position: absolute;
  top: 200px;
  left: -120px;
  width: 200px;
  height: 400px;
  background-image: url('/img/horse.png');
  background-size: cover;
  -webkit-transform: rotateX(0deg) rotateY(180deg) rotateZ(-25deg);
  -moz-transform: rotateX(0deg) rotateY(180deg) rotateZ(-25deg);
  transform: rotateX(0deg) rotateY(180deg) rotateZ(-25deg);
}
</style>