<template>
    <b-row class="section-container mt-5" style="padding-bottom: 5rem;">
        <b-col cols="12">
            <div class="title text-uppercase text-white">Officerek</div>
        </b-col>
        <b-col class="mt-5 text-center" cols="6" md="3" lg="3" v-for="officer in officers" :key="officer.name">
            <div>
                <a :href="officer.url" target="_blank">
                    <img :src="officer.avatar" alt="pic"/>
                </a>
            </div>
            <div>
                <a :href="officer.url" class="text-center" style="font-size: 18px;" :style="{color: officer.color}" target="_blank">{{officer.name}}</a>
            </div>
        </b-col>
    </b-row>
</template>

<script>
export default {
    name: "officers",
    data() {
        return {
            officers: [
                {
                    name: 'Folken',
                    avatar: '//render-eu.worldofwarcraft.com/character/ragnaros/171/181927851-avatar.jpg?alt=wow/static/images/2d/avatar/10-0.jpg',
                    url: 'https://worldofwarcraft.com/en-gb/character/eu/ragnaros/Folken',
                    color: '#c41f3b'
                },
                {
                    name: 'Benya',
                    avatar: '//render-eu.worldofwarcraft.com/character/ragnaros/23/181907735-avatar.jpg?alt=wow/static/images/2d/avatar/8-0.jpg',
                    url: 'https://worldofwarcraft.com/en-gb/character/eu/ragnaros/Benya',
                    color: '#ff7d0a'
                },
                {
                    name: 'Eklonko',
                    avatar: '//render-eu.worldofwarcraft.com/character/ragnaros/166/181895078-avatar.jpg?alt=wow/static/images/2d/avatar/10-1.jpg',
                    url: 'https://worldofwarcraft.com/en-gb/character/eu/ragnaros/Eklonko',
                    color: '#fff569'
                },
                {
                    name: 'Icyer',
                    avatar: '//render-eu.worldofwarcraft.com/character/ragnaros/127/181899647-avatar.jpg?alt=wow/static/images/2d/avatar/8-1.jpg',
                    url: 'https://worldofwarcraft.com/en-gb/character/eu/ragnaros/Icyer',
                    color: '#69ccf0'
                },
                {
                    name: 'Kalithas',
                    avatar: '//render-eu.worldofwarcraft.com/character/ragnaros/248/182170872-avatar.jpg?alt=wow/static/images/2d/avatar/27-0.jpg',
                    url: 'https://worldofwarcraft.com/en-gb/character/eu/ragnaros/Kalithas',
                    color: '#ffffff'
                },
                {
                    name: 'Ronika',
                    avatar: '//render-eu.worldofwarcraft.com/character/ragnaros/198/181914566-avatar.jpg?alt=wow/static/images/2d/avatar/2-1.jpg',
                    url: 'https://worldofwarcraft.com/en-gb/character/eu/ragnaros/Ronika',
                    color: '#0070de'
                },
                {
                    name: 'Triixya',
                    avatar: '//render-eu.worldofwarcraft.com/character/ragnaros/123/97581947-avatar.jpg?alt=wow/static/images/2d/avatar/10-1.jpg',
                    url: 'https://worldofwarcraft.com/en-gb/character/eu/ragnaros/Triixya',
                    color: '#f58cba'
                },
                {
                    name: 'Xoth',
                    avatar: '//render-eu.worldofwarcraft.com/character/ragnaros/39/181894951-avatar.jpg?alt=wow/static/images/2d/avatar/2-0.jpg',
                    url: 'https://worldofwarcraft.com/en-gb/character/eu/ragnaros/Xoth',
                    color: '#0070de'
                }
            ]
        }
    }
}
</script>

<style scoped>

</style>