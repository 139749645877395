<template>
    <div class="introduction-page">
        <div class="overflow-hidden" :class="bodyClass">
            <parallax-page-header class="clear-filter" filter-color="introduction-cover-shadow" :src="heroSrc"
                                  :title="title" :social-media="socialMedia" :text="text"
                                  :verticalPosition="parallaxVerticalPosition" :bigger="true" :z-index="-1"/>
            <parallax class="page-parallax-bg"></parallax>
            <div style="width: 100%;" class="clear-filter" filter-color="introduction-page-shadow">
                <div class="container">
                    <IntroductionContent :paragraph-class="paragraphClass" :paragraph-col-class="paragraphColClass" />
                    <div class="page-style-2" :class="paragraphClass">
                        <div :class="paragraphColClass">
                            <div class="text-justify">
                                <h3 class="title text-center">Általános szabályok:</h3>
                                <h4>Viselkedés és magatartás guilden belül:</h4>
                                <p>
                                    Tagjaink kötelesek mindenkor tiszteletet és megbecsülést tanúsítani játékos
                                    társaikkal szemben. Más guildek tagjaival szemben is elvárt a minimális empátia,
                                    valamint viselkedésével senki nem hozhat szégyent a közösségre. Bárminemű ellentét
                                    kialakulásakor, annak rendezéséhez a megfelelő kommunikációs csatornát (Discord)
                                    kell igénybe venni, és az érintett felek mellett, a vezetőség bevonása is opció a
                                    konfliktus megoldására.
                                </p>
                                <h4>Hiányzások és távollétek:</h4>
                                <p>
                                    Minden eseti távollétet a fórumon a megfelelő topikban kell jelezni. Hosszabb idejű
                                    távollét esetén – megfelelő rangra fokozással (kabala) – tiszteletben tartjuk a
                                    döntést. Pihenésüket eltöltve visszavárjuk őket régi rangjukon.
                                    Azon játékosok, akik értesítés nélkül 1 hónapja offline közegben vannak, lefokozásra
                                    kerülnek. A próbaidős rangon lévő játékos 1 hónap elteltével automatikusan el lesz
                                    távolítva a guildből.
                                    Az általános, viselkedési és magatartási szabályok be nem tartása miatt egyszeri
                                    szóbeli figyelmeztetésben részesül a játékos, durva áthágásuk viszont azonnali
                                    eltávolítást eredményez.
                                </p>

                            </div>
                        </div>
                    </div>
                    <div class="page-style-2" :class="paragraphClass">
                        <div :class="paragraphColClass">
                            <div class="text-justify">
                                <h3 class="title text-center">A guild rangjainak felépítése:</h3>
                                <p>
                                    Guild Master » Officer » Officer alt » Hero » Raider » Tag » Próbaidős » Alt »
                                    Kabala
                                </p>
                                <p>
                                    Extra rang: aZacsi felelős
                                </p>
                                <h4 class="title text-center">Guild Master:</h4>
                                <p>
                                    A klán vezetője.
                                    Döntési jogköre mindenre kiterjedő, ami a tagfelvétellel, a klán életével és annak
                                    mindennapjaival kapcsolatos.

                                </p>
                                <h4 class="title text-center">Officer:</h4>
                                <p>
                                    Döntési jogkörük mindenre kiterjedő, ami a tagfelvétellel, a klán életével és annak
                                    mindennapjaival kapcsolatos.
                                </p>
                                <h4 class="title text-center">Hero:</h4>
                                <p>
                                    A rang alapkövetelménye a karakter és az aktuális raid taktikák „maximális” átfogó
                                    ismerete. A guild alap játékosai, akiknek a mythic progressen elvárt a részvételük.
                                    Feladatuk a játékosok gem, enchant, talent és felkészültség terén való ellenőrzése,
                                    resetenként. Jogosultak a játékosok elmaradásaira felhívni figyelmüket, és elmaradás
                                    pótlásának esetén az officereknek jelezni mínusz DKP-ra való igényüket. A nem-raid
                                    contentből elérhető maximális ilvl-re való látványos törekvés, és ennek megfelelő
                                    teljesítmény, rájuk osztott raid mechanika elvégzése és havi max 1 raidről való
                                    hiányzás. A rang megszerzése: Jelentkezni szükséges, játékteljesítmény és officeri
                                    döntés alapján érhető el. Klánban töltött idő: minimum fél év.
                                    A klánbank a teljes havi poti adagot (raidenként 1 stacket) és ingyenes guildes
                                    javítást biztosít számukra.

                                </p>
                                <h4 class="title text-center">Raider:</h4>
                                <p>
                                    A rang alapkövetelménye a karakter és az aktuális raid taktikák „maximális” átfogó
                                    ismerete. A guild alap játékosai, akikre mythic progressen is számítunk. Feladatuk a
                                    játékosok gem, enchant, talent és felkészültség terén való ellenőrzése. A nem-raid
                                    contentből elérhető maximális ilvl-re való látványos törekvés, és ennek megfelelő
                                    teljesítmény, rájuk osztott raid mechanika elvégzése és havi max 3 raidről való
                                    hiányzás. A rang megszerzése: játékteljesítmény és officeri döntés alapján érhető
                                    el, jelentkezni nem szükséges.

                                </p>
                                <h4 class="title text-center">Tag:</h4>
                                <p>
                                    A próbaidő lejárta után bebizonyították, hogy a csapat tagjai és aktív résztvevői a
                                    klán életének.
                                </p>
                                <h4 class="title text-center">Próbaidős:</h4>
                                <p>
                                    Probaidős rangra kerül az újonnan felvételt nyert játékos. A rang 3-4 hét próbaidőt
                                    követel, ezalatt az idő alatt megismerkedik a többi “számítózós” arccal, a guild
                                    életével és mindenkori környezetével.
                                </p>
                                <h4 class="title text-center">Alt:</h4>
                                <p>
                                    A klán tagjainak alt karakterei. Az alt karakterek száma nincs meghatározva.
                                </p>
                                <h4 class="title text-center">Kabala:</h4>
                                <p>
                                    Azon Tiszteletbeli tagok, akik meghatározó tagok voltak a guild életében, de már nem
                                    aktívak.
                                </p>
                                <h4 class="title text-center">aZacsi felelős:</h4>
                                <p>
                                    A klán mindenkori acsipont növekedésének felelőse. Feladata: Türelmes tanácsadás
                                    biztosítása, aZacsizni kívánó társai számára. Ingyenes guild javítást biztosítunk
                                    neki.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="page-style-2" :class="paragraphClass">
                        <div :class="paragraphColClass">
                            <div class="text-justify">
                                <h3 class="title text-center">Raid szabályzat:</h3>
                                <p>
                                    A klán szeretne minden tagjának játéklehetőséget biztosítani, ezért a raideken nem
                                    fix spotok vannak. Az officerek döntése alapján rotálva kerül mindenki beosztásra,
                                    ezt a jelentkező tudomásul veszi és elfogadja.
                                    Elsősorban FOGADD EL, HOGY EZ 1 JÁTÉK!!!
                                    Figyelembe kell venni azt, hogy raidelni mindenki szórakozásból jár fel, és hogy jól
                                    érezze magát! Ha valami problémád van - mert miért ne lenne - törekedni kell arra,
                                    hogy ez a teljesítményeden, illetve a magatartásodon se látsszon meg. Ha ezt nem
                                    tudod megoldani akkor inkább "vegyél ki 1 szabadnapot".
                                </p>
                                <h4>Raid-jelentkezés:</h4>
                                <p>Discordon, a “Raid-jelentkezés” szobában történik. (elérhetőség: WoW/„Guildinfo” -ban
                                    található.)</p>
                                <h4>Raiden való részvétel:</h4>
                                <ul>
                                    <li>
                                        Raid inv: 19:45-19.55-ig

                                    </li>
                                    <li>
                                        Raid kezdés: 20:00-kor
                                    </li>
                                </ul>
                                <h4>Kommunikáció:</h4>
                                <p>
                                    Raiden történő kommunikáció Discordon történik. (elérhetőség: WoW/„Guildinfo” -ban
                                    található.)
                                </p>
                                <p>
                                    Raid alatt a kommunikáció hétköznapi hangulatban telik, pull után viszont a raid
                                    vezetőié a szó. Ez alól az egyetlen kivételt a fontos mechanikai elemekkel
                                    kapcsolatos közlés képezi.
                                </p>
                                <h4>Példa:</h4>
                                <p>
                                    Jó: „A kettes soakoló csapatba csere kell!” ,vagy „A hármas szakító csapatba a jobb
                                    oldalra csere kell!”
                                </p>
                                <p>
                                    Rossz: „Meghaltam, mert berepült egy szúnyog a szobába, amitől megijedt a kutya és
                                    felugrott az ölembe, miközben az asszony egy duplacsövűvel próbálta levadászni a
                                    vérszívó rovart!”
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="page-style-2" :class="paragraphClass">
                        <div :class="paragraphColClass">
                            <div class="text-justify">
                                <h3 class="title text-center">Raiden elvárt magatartás::</h3>

                                <p>
                                    A officerek az invelést követően tájékoztatják a csapatot, az aznapi programról.
                                </p>
                                <p>
                                    Elvárjuk, hogy a raiden a taktikai ismertetést mindenki maximálisan figyelemmel
                                    kövesse, (discordon videók lesznek a guild által használt taktikákról/módszerekről
                                    bossonként) különösképpen figyelje meg a rá vonatkozó részt, egyéni feladatokat
                                </p>
                                <h4>Taktika-lottó:</h4>
                                <p>
                                    Miután megöltünk egy bosst, és a kialakult egyéni taktikánk kikerült
                                    videó-formátumban a discordra, a következő próbálkozás alkalmával indulhat a
                                    Taktika-lottó!
                                </p>
                                <p>
                                    Trash-elés közben egy officer a raider- ,tag- és próbaidős létszám összegének
                                    megfelelő dobást indít, és a szerencsés nyertes megoszthatja társaival a taktikai
                                    finomságokat, amik a boss leküzdése során a kerekesszékes bandánk számára lehetővé
                                    tette a killt.
                                </p>
                                <p>
                                    “Kiváló” minőségű összefoglaló esetén az officerek DKP-val honorálhatják az előadót.
                                    “Minősíthetetlen” összefoglaló esetén, játékostársunk távozhat a raidből, hogy
                                    további értékes felkészüléssel töltse az idejét.
                                </p>
                                <p>
                                    Loot osztás alatt, a raid chaten és Discordon nincs helye egyéb kommunikációnak.
                                </p>
                                <p>
                                    Amennyiben valakinek AFK-ra van szüksége, azt a Discordon jeleznie kell.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="page-style-2" :class="paragraphClass">
                        <div :class="paragraphColClass">
                            <div class="text-justify">
                                <h3 class="title text-center">Raiden használt kötelező addonok:</h3>

                                <ul>
                                    <li>
                                        auto group loot pass https://wago.io/dZyjO8RJC
                                    </li>
                                    <li>
                                        Method (Exorsus) Raid tools
                                    </li>
                                    <li>
                                        DBM
                                    </li>
                                    <li>
                                        Weak auras - encounter/raidspecifikus
                                    </li>
                                </ul>
                            </div>

                        </div>
                      <div class="page-style-2" :class="paragraphClass">
                        <div :class="paragraphColClass">
                          <div class="text-justify">
                            <h3 class="title text-center">Loot:</h3>
                            <p>A loot master végzi a loot osztást, erre a szerepre a GM és az Officerek
                              jelölnek ki egy embert, raidenként.
                            </p>
                            <h4>A loot a következő szempontok alapján kerül kiosztásra:
                            </h4>
                            <ol>
                              <li>
                                Rangod a guildben: A Gm/Officer/Hero/Raider/Tag stb. magasabb rendű egy
                                próbaidősnél.
                              </li>
                              <li>
                                DKP licit
                              </li>
                              <li>
                                Geared, ami van összességében (ez a setbonusnál fontos igazán, a
                                setbonus mindig előrébb való, lásd lent*)
                              </li>
                              <li>
                                BIS lista (adott karakter BIS iteme, természetesen a buildtől is függ)
                              </li>
                            </ol>
                            <p>
                              A fenti szempontok figyelembe vétele, és a licit lefolyása után az
                              officerek fenntartják a változtatás jogát.
                            </p>
                            <p>
                              A BOE lootokra alapesetben a guildbank igényt tart, a raid consumable-ek
                              biztosításának finanszírozására. Ettől való eltérés a raid megkezdésekor
                              közlésre kerül.
                            </p>
                            <p>
                              A guildbankból a klán tagjai igényelhetik a BOE lootokat az aktuális AH
                              ár feléért.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="page-style-2" :class="paragraphClass">
                        <div :class="paragraphColClass">
                          <div class="text-justify">
                            <h3 class="title text-center">DKP:</h3>

                            <p>
                              10 DKP/teljes óra
                            </p>
                            <p>
                              Guild FBK: officeri döntés alapján (10-30) DKP
                            </p>
                            <p>
                              tartalékos játékosok: Elérhető a raiden, megkapja a fele óránkénti DKP-t,
                              amíg nem jelzi távozását. Officeri jelzés esetén 5 perce van reagálni.
                              Amennyiben reagál, és valamilyen elfoglaltság miatt időt kér, ez további 5
                              perccel kitolható (ez instázásra nem vonatkozik, onnan azonnal távozni kell
                              raidvezetési felhívás esetén). A raidbe bekerülő tartalékos játékosok a
                              teljes raid időre a teljes DKP-t fogja megkapni. Ez a raidről kikerülő,
                              tartalékossá váló játékosokra is érvényes.
                            </p>
                            <p>
                              A raid setupba bekerült játékosok: teljes, raidben töltött óránként 10 DKP-t
                              kapnak.
                            </p>
                            <p>
                              A tárgyliciten elköltött dkp-n kívül minden DKP(+/-) a raid végén kerül
                              jóváírásra.
                            </p>
                            <h4>Negatív DKP zóna</h4>
                            <p>két módon lehet negatív DKP-t szerezni:</p>
                            <ul>
                              <li>büntetés</li>
                              <li>
                                amennyiben senki másnak nem kell egy tárgy, elvihető alap DKP-ért, így
                                mínuszba is fordulhatsz.
                              </li>
                            </ul>
                            <p>
                              A negatív DKP mértéke nincs limitálva. Amennyiben két negatív DKP-val
                              rendelkező játékos jelentkezik egy tárgyra, első sorban a rangjuk dönt
                              (HERO/OFFICER/RAIDER >> TAG >> KABALA), azonos rang esetén a DKP mennyisége,
                              azonos mennyiség esetén pedig roll.
                            </p>
                            <h4>DKP Büntetések:</h4>
                            <ul>
                              <li class="pb-4">
                                Ha jelentkeztél a raidre, de nem vagy online raid kezdés előtt
                                legkésőbb 5
                                perccel (19:55), és nem írsz discordon, vagy jelzel egyéb módon: -30 DKP
                              </li>
                              <li class="pb-4">
                                Ha valaki nem jelentkezett fel a raidre, de mégis jelen van, nem kaphat
                                tartalékos DKP-t. Amennyiben utólag mégis bekerül a raidbe, jár a DKP a
                                teljes bent töltött órákért.
                              </li>
                              <li class="pb-4">
                                Minusz dkp kiszabható az alapvető viselkedési problémák, hanyagság, nem
                                jelzett AFK, raid elhagyás, folyamatos hibázás, raidet hátráltató
                                magatartás
                                (pl. Vonat bármikor, vagy deathroll taktika/fontos megbeszélés alatt) és
                                az
                                alapvető higéniai szabályok megsértése miatt. Officeri döntés alapján:
                                -(5-30) DKP
                              </li>
                              <li class="pb-4">
                                Loot need-del vagy greed-del való elvitele és tradelhetőségének
                                elvesztése
                                DKP resettel jár, valamint DKP szerzéstől való eltiltással, 2
                                végigjátszott
                                raid erejéig. A második ilyen esetnél elbúcsúzunk ezen játékosunktól.

                              </li>
                            </ul>
                            <h4>Tárgyak alap ára:</h4>
                            <ul>
                              <li class="list-2c">
                                <div>Bracer, hands, back, feet, ring, neck</div>
                                <div>20 DKP</div>

                              </li>
                              <li class="list-2c">
                                <div>fegyver 2h</div>
                                <div>50 DKP</div>
                              </li>
                              <li class="list-2c">
                                <div>Set item slot, fegyver 1h / shield / off hand / trinket</div>
                                <div>25 DKP</div>
                              </li>

                            </ul>
                            <p>
                              Licitálni 5 DKP emelésével lehet.
                            </p>
                            <p>
                              A licit 30 másodpercig tart.
                            </p>
                            <p>
                              Fals licitálás esetén (ha nincs annyi DKP-éd, „nem is kell a tárgy, csak az
                              árát akartam húzni”) és újraindul a licit, az utolsó érvényes licittől.):
                              -50 DKP
                            </p>
                            <p>
                              Azonos rangú DKP all in-nél első sorban a rangjuk dönt ((HERO || OFFICER ||
                              RAIDER) >> TAG >> KABALA), azonos rang esetén pedig roll.
                            </p>

                          </div>
                          <h4>
                            DKP RESET
                          </h4>
                          <p>Content patchnél DKP reset, és kezdő dkp osztás teljesítmény alapján.</p>
                          <h4>HERO ranggal járó loot előny:</h4>
                          <p>
                            Egy HERO rangú játékos raid resetenként 1x “hero”-zhat, ez 100 DKP-ra emeli az
                            összes tárgy alapárát és innentől csak a többi HERO licitálhat, a “hero”-zás
                            felhasználásával, de a vesztes(ek) nem veszítenek DKP-t.
                          </p>
                          <p>
                            Kezdő DKP - officer/hero/raider: 50 DKP
                          </p>
                          <h4>
                            Egyéb:
                          </h4>
                          <p>
                            Minden, a szabályzatban nem részletezett kérdésben a vezetőség feladata dönteni
                            és intézkedni.
                          </p>
                          <p>
                            A változtatás jogát fenntartjuk!
                          </p>
                        </div>
                      </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import ParallaxPageHeader from "../components/ParallaxPageHeader";
    import IntroductionContent from "@/pages/introduction/IntroductionContent";

    export default {
        name: 'rules',
        components: {
            ParallaxPageHeader,
          IntroductionContent
        },
        computed: {
            windowWidth() {
                return this.$root.windowWidth;
            },
            isMobile() {
                return this.$root.isMobile;
            },
            paragraphClass() {
                if (this.isMobile) return ['m-page-paragraph'];
                else return ['page-paragraph'];
            },
            bodyClass() {
                return ['page-body'];
            },
            paragraphColClass() {
                return ['col-12', 'col-md-11', 'col-lg-10', 'col-xl-9', 'm-auto'];
            }
        },
        data() {
            return {
                heroSrc: '/img/hero-tgf.jpg',
                title: 'pages.rules',
                text: '',
                socialMedia: false,
                parallaxVerticalPosition: 'top'
            };
        }
    };
</script>

<style scoped>
    .page-parallax-bg {
        background-image: url("/img/rules.png");
        background-size: contain;
        background-position: center;
        z-index: -3;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
    }
</style>