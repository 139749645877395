<template>
    <div id="app" class="overflow-hidden">
        <router-view name="header"/>
        <div class="wrapper">
            <router-view/>
        </div>
        <router-view name="footer"/>
    </div>
</template>
<script>
    export default {
    };
</script>
<style>
    #app {
    }
</style>
