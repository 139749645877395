<template>
  <footer
    class="footer"
    :class="{ [`footer-${type}`]: type }"
    :data-background-color="backgroundColor"
  >
    <div class="container">
      <nav class="col-sm-12 col-md-6 text-left">
        <ul>
          <li>
              <a href="https://raider.io/guilds/eu/ragnaros/Silver%20River"
                 style="color: white;" target="_blank">
                  <img style="width: 20px;" src="img/raiderio.webp" alt="raiderio"/> Raider IO
              </a>
              <a href="https://www.warcraftlogs.com/guild/progress/10106"
                 style="color: white;" target="_blank">
                  <img style="width: 20px;" src="img/warcraftlogs.png" alt="warcraftlogs"/> Warcraftlogs
              </a>
              <a href="https://www.wowprogress.com/guild/eu/ragnaros/Silver+River"
                 style="color: white;" target="_blank">
                  <img style="width: 20px;" src="img/wowprogress.png" alt="wowprogress"/> WoWProgress
              </a>
          </li>
        </ul>
      </nav>
      <div class="copyright col-lg-4 col-sm-12 text-right">
        &copy; {{ year }} {{$t('siteName')}}™
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  props: {
    backgroundColor: String,
    type: String
  },
  data() {
    return {
      year: new Date().getFullYear()
    };
  }
};
</script>
<style></style>
